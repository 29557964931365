import React from 'react';

import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip as BaseTooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';

interface IProps {
  id: string;
  label?: string | null;
  placement?: string | null;
  children: React.ReactNode;
}

const Tooltip: React.FC<IProps> = React.memo(({ id, label, children, placement = 'left' }) => {
  if (!label) {
    return <div className="d-inline-flex align-items-center">{children}</div>;
  }

  return (
    <OverlayTrigger
      placement={placement as Placement}
      overlay={<BaseTooltip id={`tooltip-${id}`}>{label}</BaseTooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div {...triggerHandler} className="d-inline-flex align-items-center">
          <div ref={ref}>{children}</div>
        </div>
      )}
    </OverlayTrigger>
  );
});

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
