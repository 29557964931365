import React, { useContext } from 'react';

import { Col, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Cv from './components/Cv';
import ProfessionalForm from './components/ProfessionalForm';
import ProfileForm from './components/ProfileForm';
import { IUser } from '../../types/user.type';
import UserContext from '../../user-context';

const EditProfile = React.memo(() => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);

  const handleSave = React.useCallback(
    (data: IUser) => {
      setUser(data);

      toast.success('Your profile was updated.');
    },
    [setUser],
  );

  if (!user) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center p-3">
      <Col lg={3} md={3} className="pe-3">
        <ProfileForm user={user} onSave={handleSave} />
      </Col>

      <Col lg={9} md={9}>
        <Tabs defaultActiveKey="professional">
          <Tab eventKey="professional" title={t('edit_profile.tabs.professional')}>
            <ProfessionalForm user={user} onSave={handleSave} />
          </Tab>

          <Tab eventKey="cv" title={t('edit_profile.tabs.cv')}>
            <Cv user={user} onSave={handleSave} />
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
});

export default EditProfile;
