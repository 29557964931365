import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AVAILABLE_LOCALE } from '../../../../constants/i18n';
import LocaleIcon from '../LocaleIcon';

const ChangeLanguage = React.memo(() => {
  const { t, i18n } = useTranslation();

  const handleChange = React.useCallback(
    (eventKey: string | null) => {
      if (!eventKey) {
        return;
      }

      i18n.changeLanguage(eventKey);
    },
    [i18n],
  );

  return (
    <div className="me-3">
      <Dropdown onSelect={handleChange}>
        <Dropdown.Toggle variant="rimary" id="dropdown-flags">
          <LocaleIcon lang={i18n.language} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {AVAILABLE_LOCALE.map((lang) => (
            <Dropdown.Item key={lang} eventKey={lang}>
              <LocaleIcon lang={lang} />

              <span>{t(`lang.${lang}`)}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
});

export default ChangeLanguage;
