import React from 'react';

import './styles.css';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';

const Auth = React.memo(() => (
  <div className="login-box">
    <div className="login">
      <SignUp />
      <SignIn />
    </div>
  </div>
));

export default Auth;
