import { ApiHelper } from '../helpers/api.helper';
import {
  IAccessToken,
  IPhoneForm,
  IRegistrationForm,
  IValidationCodeForm,
} from '../types/security.type';
import { IStatusResponse } from '../types/status-response.type';

const url = 'security';

export const sendRegistration = async (params: IRegistrationForm): Promise<IStatusResponse> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(key, (params as any)[key]);
  });

  const response = await fetch(ApiHelper.makeUrl(`${url}/registration`), {
    method: 'POST',
    body: formData,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const sendVerificationCode = async (params: IPhoneForm): Promise<IStatusResponse> => {
  const response = await fetch(ApiHelper.makeUrl(`${url}/auth/step-one`), {
    method: 'POST',
    body: JSON.stringify(params),
    ...ApiHelper.makeOptions(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const validateVerificationCode = async (
  params: IValidationCodeForm,
): Promise<IAccessToken> => {
  const response = await fetch(ApiHelper.makeUrl(`${url}/auth/step-two`), {
    method: 'POST',
    body: JSON.stringify(params),
    ...ApiHelper.makeOptions(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};
