import { ApiHelper } from '../helpers/api.helper';
import { IStatusResponse } from '../types/status-response.type';

export const sendInformationRequest = async (): Promise<IStatusResponse> => {
  const response = await fetch(ApiHelper.makeUrl('information-request'), {
    method: 'POST',
    ...ApiHelper.makeOptions(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};
