import React from 'react';

import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

interface IProps {
  src?: string | null;
}

const Avatar: React.FC<IProps> = React.memo(({ src = null }) => {
  if (!src) return null;

  return <Image src={src} roundedCircle alt="Avatar" data-testid="avatar" />;
});

Avatar.propTypes = {
  src: PropTypes.string,
};

export default Avatar;
