import React from 'react';

import PropTypes from 'prop-types';
import { FiSettings } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface IProps {
  size?: number;
}

const EditProfileButton: React.FC<IProps> = React.memo(({ size = 18 }) => (
  <Link to="/edit" className="icon-link me-2" data-testid="edit-profile-button">
    <FiSettings size={size} />
  </Link>
));

EditProfileButton.propTypes = {
  size: PropTypes.number,
};

export default EditProfileButton;
