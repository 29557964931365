import React from 'react';

import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IProps {
  label: string;
  isProcess: boolean;
  isDisabled: boolean;
}

const SubmitButton: React.FC<IProps> = React.memo(({ label, isProcess, isDisabled }) => {
  const { t } = useTranslation();

  if (isProcess) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Spinner animation="grow" variant="primary" role="status">
          <span className="visually-hidden">{t('general.loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Button type="submit" variant="primary" className="w-100 mt-3" disabled={isDisabled}>
      {label}
    </Button>
  );
});

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default SubmitButton;
