import React from 'react';

import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCircleCheck } from 'react-icons/fa6';

import PageWrapper from '../../components/PageWrapper';

const PaymentSuccessful = React.memo(() => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Card className="align-items-center">
        <Card.Body>
          <Card.Title className="d-flex align-items-center mb-4 justify-content-center">
            <FaCircleCheck size={60} className="me-3 text-success" />

            <h1 className="text-success m-0">{t('payment_success.title')}</h1>
          </Card.Title>

          <h3>{t('payment_success.info')}</h3>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
});

export default PaymentSuccessful;
