import React from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import SubmitButton from '../../../../../../components/SubmitButton';
import TextField from '../../../../../../components/TextField';
import { IPhoneForm } from '../../../../../../types/security.type';

interface IProps {
  isProcess: boolean;
  onSubmit: (values: IPhoneForm) => void;
}

const PhoneForm: React.FC<IProps> = React.memo(({ isProcess, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        phone: '',
      }}
      validationSchema={Yup.object({
        phone: Yup.string()
          .matches(/^\+[\d]{9,15}$/, 'Phone number is not valid. Valid example: +33123456789')
          .required(t('general.required')),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="headline">{t('signin.verification.code')}</div>

          <TextField
            type="text"
            label={t('signin.verification.info')}
            id="phone"
            {...formik.getFieldProps('phone')}
          />

          <SubmitButton
            label={t('signin.verification.send')}
            isProcess={isProcess}
            isDisabled={!formik.dirty || !formik.isValid || isProcess}
          />
        </Form>
      )}
    </Formik>
  );
});

PhoneForm.propTypes = {
  isProcess: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PhoneForm;
