import React from 'react';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa6';

import FileField from '../../../../components/FileField';
import './styles.css';

interface IProps {
  name: string;
  isProcess: boolean;
  isExist?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    version?: string,
  ) => void;
  onRemove: () => void;
  images?: string[] | null;
}

const CvForm: React.FC<IProps> = React.memo(
  ({ name, isProcess, isExist = false, onChange, onRemove, images }) => {
    const { t } = useTranslation();

    const renderPreview = React.useMemo(() => {
      if (!images?.length) {
        return <div className="text-center">{t('edit_profile.cv_preview')}</div>;
      }

      return (
        <div className="cv-images">
          {images.map((image) => (
            <img key={image} src={`${image}?${Date.now()}`} alt={image} />
          ))}
        </div>
      );
    }, [images, t]);

    return (
      <>
        <Col lg={12} md={12}>
          <Formik initialValues={{ [name]: '' }} onSubmit={() => {}}>
            {(formik) => (
              <>
                <div className="d-flex">
                  <div className={`upload-cv ${isProcess ? 'd-none' : ''}`}>
                    <FileField
                      id={name}
                      label=""
                      onlyButton
                      {...formik.getFieldProps(name)}
                      disabled={isProcess}
                      onChange={onChange}
                    />
                  </div>

                  {!isProcess && isExist && (
                    <Button
                      type="button"
                      variant="outline-danger"
                      className="remove-button ms-1"
                      onClick={onRemove}
                    >
                      <FaTrash />
                    </Button>
                  )}
                </div>

                {isProcess && (
                  <Col className="mt-3 mb-3 d-flex">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">{t('general.loading')}</span>
                    </Spinner>
                  </Col>
                )}
              </>
            )}
          </Formik>
        </Col>

        <Col lg={12} md={12} className="mt-3">
          {renderPreview}
        </Col>
      </>
    );
  },
);

CvForm.propTypes = {
  name: PropTypes.string.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isExist: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  images: PropTypes.array,
};

export default CvForm;
