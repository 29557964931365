import React, { useEffect } from 'react';

import { fetchUser } from '../api/user';
import { STORAGE_TOKEN_KEY } from '../constants/storage-key';
import { IUser } from '../types/user.type';

export const useFetchUser = () => {
  const [user, setUser] = React.useState<IUser | null>(null);
  const [isFetching, setIsFetching] = React.useState<boolean>(true);

  useEffect(() => {
    const initUser = async () => {
      const token = localStorage.getItem(STORAGE_TOKEN_KEY);

      try {
        if (token) {
          const responseUser = await fetchUser();

          if (responseUser) {
            setUser(responseUser);
          } else {
            setUser(null);
          }
        }
      } catch (e) {
        setUser(null);
      }

      setIsFetching(false);
    };

    initUser();
  }, []);

  return { user, setUser, isFetching };
};
