import React from 'react';

import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';
import './styles.css';
import Select from 'react-select';

import { ISelectItem } from './types';

type IProps = React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    label: string;
    name: string;
    options: ISelectItem[];
  };

const SelectField: React.FC<IProps> = React.memo(({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { value, touched, error } = meta;

  const findValue = () => {
    if ((value === 0 || value === false || value) && props.options) {
      return props.options.find((option) => option.value === value);
    }

    return null;
  };

  return (
    <FormGroup className="form-group">
      {label && <FormLabel className="label">{label}</FormLabel>}

      <div className="select-wrapper">
        <Select
          {...field}
          {...props}
          defaultValue={null}
          value={findValue()}
          onChange={(selectedOption) => {
            helpers.setValue(selectedOption?.value);
          }}
          isClearable={false}
          styles={{
            control: (styles) => ({
              ...styles,
              cursor: 'pointer',
              borderRadius: 0,
              boxShadow: 'none',
              borderColor: '#ced4da',
              ':hover': {
                borderColor: '#ced4da',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected || state.isFocused ? 'white' : '#495057',
              backgroundColor: state.isSelected || state.isFocused ? '#0000ff' : 'white',
              cursor: 'pointer',
              ':active': {
                backgroundColor: '#0000ff',
              },
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: '#ced4da',
              ':hover': {
                borderColor: '#999999',
              },
            }),
            menu: (provided) => ({
              ...provided,
              borderRadius: 0,
              marginTop: 0,
              boxShadow: undefined,
              borderWidth: '1px',
              borderTopWidth: '0',
              borderColor: '#ced4da',
              borderStyle: 'solid',
            }),
            menuList: (provided) => ({
              ...provided,
              borderRadius: 0,
              marginTop: 0,
              boxShadow: undefined,
              padding: 0,
            }),
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: 'white',
              borderColor: '#495057',
              borderRadius: '11px',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '.125rem 0 .125rem 0',
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              fontSize: '12px',
              padding: 0,
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              ':hover': {
                color: '#0000ff',
              },
            }),
          }}
        />
      </div>

      {touched && error ? (
        <FormControl.Feedback type="invalid" className="d-block">
          {error}
        </FormControl.Feedback>
      ) : null}
    </FormGroup>
  );
});

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SelectField;
