import * as Yup from 'yup';

export default Yup.object({
  address: Yup.string().max(255, 'Must be 255 characters or less'),
  addressSecondary: Yup.string().max(255, 'Must be 255 characters or less'),
  postalcode: Yup.string().max(255, 'Must be 255 characters or less'),
  city: Yup.string().max(255, 'Must be 255 characters or less'),
  country: Yup.string(),
  emailSecondary: Yup.string(),
  phoneProfessional: Yup.string(),
});
