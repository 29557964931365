import { STORAGE_TOKEN_KEY } from '../constants/storage-key';

export class ApiHelper {
  /**
   * Make API request url
   * @param path
   */
  static makeUrl(path: string): string {
    return `${process.env.REACT_APP_API_URL}/${path}`;
  }

  /**
   * Make API request options
   */
  static makeOptions(isJson = true): Partial<RequestInit> {
    const token = localStorage.getItem(STORAGE_TOKEN_KEY);

    const headers: { [key: string]: any } = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (isJson) {
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
    }

    return {
      headers,
    };
  }
}
