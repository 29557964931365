import React from 'react';

import './styles.css';
import { useTranslation } from 'react-i18next';

import PhoneForm from './components/PhoneForm';
import VerificationCodeForm from './components/VerificationCodeForm';
import { sendVerificationCode, validateVerificationCode } from '../../../../api/security';
import { useAuth } from '../../../../hooks/useAuth';
import { IPhoneForm, IVerificationCodeForm } from '../../../../types/security.type';

const SignIn = React.memo(() => {
  const { t } = useTranslation();

  const [step, setStep] = React.useState<number>(1);
  const [isProcess, setProcess] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string | null>(null);

  const { authorize } = useAuth();

  const onSubmitPhoneForm = React.useCallback(async (values: IPhoneForm) => {
    setProcess(true);

    try {
      const { status } = await sendVerificationCode(values);

      if (status) {
        setStep(2);
        setPhone(values.phone);
      }
    } finally {
      setProcess(false);
    }
  }, []);

  const onSubmitVerificationCodeForm = React.useCallback(
    async (values: IVerificationCodeForm) => {
      setProcess(true);

      try {
        const response = await validateVerificationCode({
          ...values,
          phone: phone!,
        });

        await authorize(response.access_token);
      } finally {
        setProcess(false);
      }
    },
    [authorize, phone],
  );

  const renderForm = () => {
    if (step === 2) {
      return <VerificationCodeForm onSubmit={onSubmitVerificationCodeForm} isProcess={isProcess} />;
    }

    return <PhoneForm onSubmit={onSubmitPhoneForm} isProcess={isProcess} />;
  };

  return (
    <div className="login-item">
      <div className="headline">{t('signin.title')}</div>
      <div className="mb-3">{t('signin.info')}</div>

      {renderForm()}
    </div>
  );
});

export default SignIn;
