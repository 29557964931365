import React from 'react';

import PropTypes from 'prop-types';

import EditProfileButton from '../EditProfileButton';
import LogoutButton from '../LogoutButton';

import './styles.css';

interface IProps {
  name: string;
}

const UserInfo: React.FC<IProps> = React.memo(({ name }) => (
  <div className="user-info">
    <span className="me-2">{name}</span>

    <EditProfileButton />
    <LogoutButton />
  </div>
));

UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
};

export default UserInfo;
