import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

interface IProps {
  children: React.ReactNode;
}

const PageWrapper: React.FC<IProps> = React.memo(({ children }) => (
  <div className="m-5 p-5">
    <Row>
      <Col lg={12} md={12}>
        {children}
      </Col>
    </Row>
  </div>
));

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
