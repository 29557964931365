import React from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import SubmitButton from '../../../../../../components/SubmitButton';
import TextField from '../../../../../../components/TextField';
import { IVerificationCodeForm } from '../../../../../../types/security.type';

interface IProps {
  isProcess: boolean;
  onSubmit: (values: IVerificationCodeForm) => void;
}

const VerificationCodeForm: React.FC<IProps> = React.memo(({ isProcess, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={Yup.object({
        code: Yup.string().required(t('general.required')),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="headline">{t('signin.validation.info')}</div>

          <TextField
            type="password"
            label={t('signin.validation.code')}
            id="code"
            {...formik.getFieldProps('code')}
          />

          <SubmitButton
            label={t('signin.validation.send')}
            isProcess={isProcess}
            isDisabled={!formik.dirty || !formik.isValid || isProcess}
          />
        </Form>
      )}
    </Formik>
  );
});

VerificationCodeForm.propTypes = {
  isProcess: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default VerificationCodeForm;
