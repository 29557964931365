import React from 'react';

import PropTypes from 'prop-types';
import './styles.css';

interface IProps {
  lang: string;
}

const LocaleIcon: React.FC<IProps> = React.memo(({ lang }) => (
  <img src={`/assets/locale/${lang}.png`} alt={lang} className="flag-icon me-1" />
));

LocaleIcon.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default LocaleIcon;
