import React from 'react';

import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';

import './styles.css';

type IProps = React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    label: string;
    type: string;
    name: string;
    iconRight?: boolean;
  };

const PhoneField: React.FC<IProps> = React.memo(({ label, iconRight, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helpers;

  const onChangeHandle = (e: { target: { value: string } }) => {
    const regExp = /[^+\d]/g;

    setValue(e.target.value.replace(regExp, ''));
  };

  return (
    <FormGroup className="form-group">
      {label && <FormLabel className="label">{label}</FormLabel>}
      <div className="input-wrapper">
        <FormControl
          {...field}
          onChange={onChangeHandle}
          {...props}
          isInvalid={touched && typeof error !== 'undefined'}
          className={`input ${iconRight === true ? 'icon-right' : ''}`}
        />

        {touched && error ? (
          <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
        ) : null}
      </div>
    </FormGroup>
  );
});

PhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconRight: PropTypes.bool,
};

export default PhoneField;
