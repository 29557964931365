import { RecordString } from './record.type';

export type IUser = RecordString<'firstname' | 'lastname' | 'email' | 'phone' | 'country'> &
  Partial<
    Record<
      | 'linkedin'
      | 'birthday'
      | 'address'
      | 'address_secondary'
      | 'city'
      | 'postalcode'
      | 'email_secondary'
      | 'phone_professional'
      | 'date_available'
      | 'picture'
      | 'cv'
      | 'cv_fr',
      string | null
    >
  > & {
    id: number;
    available: number;
    cv_images?: string[] | null;
    cv_fr_images?: string[] | null;
    is_coaching_paid: boolean;
  };

export enum IUserCv {
  english = 'en',
  french = 'fr',
}
