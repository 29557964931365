import { useContext } from 'react';

import { fetchUser } from '../api/user';
import { STORAGE_TOKEN_KEY } from '../constants/storage-key';
import UserContext from '../user-context';

export const useAuth = () => {
  const { user, setUser } = useContext(UserContext);

  const authorize = async (token: string) => {
    localStorage.setItem(STORAGE_TOKEN_KEY, token);

    const response = await fetchUser();

    setUser(response);
  };

  return {
    isAuth: user !== null,
    authorize,
  };
};
