import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiGlobe } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { IProfileForm } from './types';
import validation from './validation';
import { updateUser, uploadPhoto } from '../../../../api/user';
import PhoneField from '../../../../components/PhoneField';
import SelectField from '../../../../components/SelectField';
import SubmitButton from '../../../../components/SubmitButton';
import TextField from '../../../../components/TextField';
import { AVAILABLES } from '../../../../constants/availables';
import { UserShape } from '../../../../shapes/user.shape';
import { IProps } from '../../types';
import PhotoForm from '../PhotoForm';

import './styles.css';

const FILE_SIZE = 2 * 1024 * 1024;

const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

const ProfileForm: React.FC<IProps> = React.memo(({ user, onSave }) => {
  const { t } = useTranslation();

  const [isProcess, setIsProcess] = useState<boolean>(false);

  const handleSubmit = React.useCallback(
    async (values: IProfileForm) => {
      setIsProcess(true);

      try {
        const response = await updateUser({
          ...user,
          ...values,
        });

        onSave(response);
      } catch {
        toast.error(t('edit_profile.error_update'));
      } finally {
        setIsProcess(false);
      }
    },
    [onSave, t, user],
  );

  const handlePhoto = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget as HTMLInputElement;

    if (!target.files?.length) {
      return;
    }

    const photo = target.files[0];

    if (!SUPPORTED_FORMATS.includes(photo.type)) {
      toast.error(t('general.error_file_format'));

      return;
    }

    if (photo.size > FILE_SIZE) {
      toast.error(t('general.error_file_size'));

      return;
    }

    setIsProcess(true);

    try {
      const response = await uploadPhoto(photo);

      onSave(response);
    } catch {
      toast.error(t('edit_profile.error_upload_cv'));
    } finally {
      target.value = '';

      setIsProcess(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        linkedin: user.linkedin ?? '',
        birthday: user.birthday ?? '',
        available: user.available ?? '',
        date_available: user.date_available ?? '',
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form className="card">
          <div className="headline">{t('edit_profile.title')}</div>

          <PhotoForm user={user} isProcess={isProcess} onUpload={handlePhoto} />

          <TextField
            type="text"
            label={t('general.first_name')}
            id="firstname"
            {...formik.getFieldProps('firstname')}
          />

          <TextField
            type="text"
            label={t('general.last_name')}
            id="lastname"
            {...formik.getFieldProps('lastname')}
          />

          <PhoneField
            type="text"
            label={t('general.phone')}
            id="phone"
            {...formik.getFieldProps('phone')}
          />

          <TextField
            type="email"
            label={t('general.email')}
            id="email"
            {...formik.getFieldProps('email')}
          />

          <div className="position-relative">
            <TextField
              type="text"
              label={t('edit_profile.linkedin')}
              id="linkedin"
              name="linkedin"
              iconRight
            />

            <a
              href={formik.values.linkedin && formik.values.linkedin}
              className="linkedin-link"
              rel="noreferrer"
              target="_blank"
              onClick={(event) => !formik.values.linkedin && event.preventDefault()}
            >
              <FiGlobe />
            </a>
          </div>

          <TextField type="date" label={t('general.birthday')} id="birthday" name="birthday" />

          <SelectField
            label={t('edit_profile.availability')}
            id="available"
            options={AVAILABLES}
            {...formik.getFieldProps('available')}
          />

          <TextField
            type="date"
            label={t('edit_profile.available_after')}
            id="dateAvailable"
            name="date_available"
          />

          <SubmitButton
            label={t('general.save')}
            isProcess={isProcess}
            isDisabled={!formik.isValid || isProcess}
          />
        </Form>
      )}
    </Formik>
  );
});

ProfileForm.propTypes = {
  user: UserShape.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ProfileForm;
