import React, { useCallback, useState } from 'react';

import { Button, Container, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { sendInformationRequest } from '../../../../api/information-request';

const InformationRequest = React.memo(() => {
  const [isShow, setShow] = useState(false);
  const [isProcess, setProcess] = useState(false);

  const handleSend = useCallback(async () => {
    setProcess(true);

    try {
      await sendInformationRequest();

      toast.success('The request was submitted.');

      setShow(false);
    } finally {
      setProcess(false);
    }
  }, []);

  return (
    <>
      <Button
        type="button"
        variant="outline-primary"
        onClick={() => setShow(true)}
        className="me-3"
      >
        Je m’inscris à la réunion d’information
      </Button>

      <Modal
        show={isShow}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>EN SAVOIR PLUS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <div className="text-center">
              Vous souhaitez en savoir plus sur le programme Mon Coach Mobilité par KINT ?
            </div>
            <br />
            <div>
              Faites-le nous savoir, nous vous contacterons au plus vite pour vous inviter à notre
              prochaine réunion d’information.
            </div>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          {isProcess && (
            <Spinner animation="grow" variant="primary" role="status" className="me-4">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}

          {!isProcess && (
            <Button variant="primary" onClick={handleSend}>
              Valider
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default InformationRequest;
