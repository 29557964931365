import React, { useContext } from 'react';

import { Badge, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Avatar from '../../components/Avatar';
import EditProfileButton from '../../components/Header/components/EditProfileButton';
import Tooltip from '../../components/Tooltip';
import { AVAILABLES } from '../../constants/availables';
import { COUNTRIES } from '../../constants/countries';
import UserContext from '../../user-context';
import './styles.css';

const Profile = React.memo(() => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const userCountry = React.useMemo(() => {
    if (!user?.country) {
      return null;
    }

    const country = COUNTRIES.find((item) => item.value === user.country);

    return country?.label ?? null;
  }, [user]);

  const isAvailable = React.useMemo(() => user?.available, [user]);

  const available = React.useMemo(() => {
    if (!user) {
      return null;
    }

    const label = AVAILABLES.find((item) => item.value === user.available);

    if (!label) {
      return null;
    }

    return t(`general.${label.label}`);
  }, [t, user]);

  if (!user) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center p-3 profile-wrapper">
      <Col lg={12} md={12}>
        <div className="card">
          <Row className="mb-5">
            <Col lg={11} md={11} className="d-flex">
              <div className="me-3">
                <Avatar src={user.picture} />
              </div>

              <div>
                <div className="profile-name">
                  <span className="me-2">
                    {user.firstname} {user.lastname}
                  </span>

                  <Tooltip
                    id="available"
                    placement="bottom"
                    label={
                      isAvailable && user.date_available
                        ? t('profile.available_from', { date: user.date_available })
                        : ''
                    }
                  >
                    <Badge pill bg={isAvailable ? 'success' : 'danger'}>
                      {available}
                    </Badge>
                  </Tooltip>
                </div>

                <div>{user.email}</div>
                <div>{user.phone}</div>
                <div>{user.birthday}</div>

                {user.linkedin && (
                  <div>
                    <Link
                      to={user.linkedin}
                      className="profile-linkedin"
                      target="_blank"
                      rel="nofollow"
                    >
                      {user.linkedin}
                    </Link>
                  </div>
                )}
              </div>
            </Col>

            <Col lg={1} md={1} className="d-flex justify-content-end align-items-start">
              <Tooltip id="profile" label={t('profile.edit_profile')}>
                <EditProfileButton size={28} />
              </Tooltip>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12}>
              <div className="headline">{t('general.address').toUpperCase()}</div>

              <div>
                <u>{t('general.address')}</u> : {user.address}
              </div>

              <div>
                <u>{t('general.address_secondary')}</u> : {user.address_secondary}
              </div>

              <div>
                <u>{t('general.postal_code')}</u> : {user.postalcode}
              </div>

              <div>
                <u>{t('general.city')}</u> : {user.city}
              </div>

              {user.country && (
                <div>
                  <u>{t('general.country')}</u> : {userCountry}
                </div>
              )}

              <div>
                <u>{t('general.email_secondary')}</u> : {user.email_secondary}
              </div>

              <div>
                <u>{t('general.phone_professional')}</u> : {user.phone_professional}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
});

export default Profile;
