import React from 'react';

import PropTypes from 'prop-types';

import Avatar from '../../../../components/Avatar';
import FileField from '../../../../components/FileField';
import { UserShape } from '../../../../shapes/user.shape';
import { IUser } from '../../../../types/user.type';

import './styles.css';

interface IProps {
  user: IUser;
  isProcess: boolean;
  onUpload: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PhotoForm: React.FC<IProps> = React.memo(({ user, isProcess, onUpload }) => (
  <div className="mb-3">
    <div className="d-flex justify-content-center mb-3">
      <Avatar src={user.picture} />
    </div>

    <div className={`upload-cv text-center ${isProcess ? 'd-none' : ''}`}>
      <FileField
        id="photo"
        label=""
        name="photo"
        onlyButton
        value={undefined}
        disabled={isProcess}
        onChange={onUpload}
      />
    </div>
  </div>
));

PhotoForm.propTypes = {
  user: UserShape.isRequired,
  isProcess: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default PhotoForm;
