import React from 'react';

import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';

import './styles.css';

type IProps = React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    label?: string;
    type: string;
    name: string;
    iconRight?: boolean;
  };

const TextField: React.FC<IProps> = React.memo(({ label, iconRight, ...props }) => {
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  return (
    <FormGroup className="form-group">
      {label && <FormLabel className="label">{label}</FormLabel>}

      <div className="input-wrapper">
        <FormControl
          {...field}
          {...props}
          isInvalid={touched && typeof error !== 'undefined'}
          className={`input ${iconRight === true ? 'icon-right' : ''}`}
          data-testid="text-input"
        />

        {touched && error ? (
          <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
        ) : null}
      </div>
    </FormGroup>
  );
});

TextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconRight: PropTypes.bool,
};

export default TextField;
