import React from 'react';

import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { IoMdCloudUpload } from 'react-icons/io';

type IProps = React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    label: string;
    name: string;
    onlyButton?: boolean;
  };

const FileField: React.FC<IProps> = React.memo(({ label, onlyButton = false, ...props }) => {
  const { t } = useTranslation();

  const [, meta, helpers] = useField(props);
  const { touched, error } = meta;

  return (
    <FormGroup className="form-group">
      {onlyButton && (
        <FormLabel className="upload-button" htmlFor={props.id}>
          <IoMdCloudUpload /> {t('general.upload')}
        </FormLabel>
      )}

      {!onlyButton && label && (
        <FormLabel className="label" htmlFor={props.id}>
          {label}
        </FormLabel>
      )}

      <FormControl
        {...props}
        type="file"
        onChange={
          props.onChange
            ? props.onChange
            : (event: React.ChangeEvent<HTMLInputElement>) =>
                helpers.setValue(event.currentTarget.files?.[0])
        }
        isInvalid={touched && typeof error !== 'undefined'}
      />

      {touched && error ? (
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      ) : null}
    </FormGroup>
  );
});

FileField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onlyButton: PropTypes.bool,
};

export default FileField;
