import React, { useContext } from 'react';

import { FiLogOut } from 'react-icons/fi';

import { STORAGE_TOKEN_KEY } from '../../../../constants/storage-key';
import UserContext from '../../../../user-context';

const LogoutButton = React.memo(() => {
  const { setUser } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.removeItem(STORAGE_TOKEN_KEY);

    setUser(null);
  };

  return (
    <FiLogOut
      size={18}
      onClick={handleLogout}
      className="cursor-pointer"
      data-testid="logout-button"
    />
  );
});

export default LogoutButton;
