import * as Yup from 'yup';

export default Yup.object({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  phone: Yup.string().matches(
    /^\+[\d]{9,15}$/,
    'Phone number is not valid. Valid example: +33123456789',
  ),
  email: Yup.string().email('Invalid email'),
  linkedin: Yup.string().max(255, 'Must be 255 characters or less'),
  birthday: Yup.date(),
});
