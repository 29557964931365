import React from 'react';

import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PageWrapper from '../../components/PageWrapper';

const NotFound: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Card className="align-items-center p-5">
        <h1>{t('not_found.info')}</h1>
      </Card>
    </PageWrapper>
  );
});

export default NotFound;
