import React, { useContext } from 'react';

import './styles.css';
import { Button } from 'react-bootstrap';
import { FaCircleCheck } from 'react-icons/fa6';

import InformationRequest from './components/InformationRequest';
import ProgramRegister from './components/ProgramRegister';
import Tooltip from '../../components/Tooltip';
import UserContext from '../../user-context';

const MobilityCoach = React.memo(() => {
  const { user } = useContext(UserContext);

  return (
    <div className="mobility-coach">
      <div className="text-center">
        <div className="page-headline mb-4">
          <span className="text-highlighted">MON COACH</span> MOBILITÉ
          {user?.is_coaching_paid && (
            <Tooltip id="is_paid" placement="bottom" label="Paid">
              <FaCircleCheck size={20} className="ms-2 mb-2" color="green" />
            </Tooltip>
          )}
        </div>

        <div className="section-info">
          <div>KINT SE LANCE DANS L’OUTPLACEMENT 5.0 !</div>
          <div>VOUS SOUHAITEZ VOUS REPOSITIONNER PROFESSIONNELLEMENT ?</div>
          <div>TROUVER UN NOUVEAU POSTE OU UNE NOUVELLE MISSION ?</div>
          <div>AVEC MON COACH MOBILITÉ, KINT VOUS ACCOMPAGNE AU QUOTIDIEN POUR REBONDIR</div>
        </div>

        <div className="section-detailed">
          <div className="mb-3">
            Ce programme de 4 mois, conçu par des experts RH, allie une application digitale de
            coaching à des ateliers collectifs organisés tous les 15 jours par les consultants KINT.
          </div>

          <div className="mb-3">
            <span className="font-bold">L’application Mon coach Mobilité</span> constitue un
            véritable « coach digital personnel », qui vous apporte cadre et méthode sous la forme
            d’un programme hebdomadaire d’actions à réaliser. Chaque lundi vous découvrez ainsi une
            nouvelle « To do list » et des conseils pour y parvenir.
          </div>

          <div className="mb-3">
            Mon coach Mobilité KINT est un programme à 360° qui vous permet de travailler votre
            projet professionnel, mais aussi d’optimiser votre organisation en tenant compte de
            votre vie personnelle, d’améliorer votre communication écrite et orale, et évidemment de
            développer et activer votre réseau !
          </div>

          <div className="mb-3">
            <span className="font-bold">Les ateliers collectifs réunissent</span> tous les 15 jours
            6 à 10 participants autour d’un consultant KINT et&nbsp;
            <span className="font-bold">d’éventuels intervenants</span>. Ils constituent le
            complément indispensable à l’appli, pour échanger bonnes pratiques et contacts, et
            lancer votre démarche de networking !
          </div>

          <div className="mb-3 font-bold">
            Vous souhaitez en savoir plus : participez à une réunion d’information ! Nous serons
            ravis de vous rencontrer et de vous présenter le programme Mon coach Mobilité KINT.
          </div>
        </div>
      </div>

      <div className="section-detailed mt-3">
        {user?.is_coaching_paid === false && (
          <>
            <InformationRequest />
            <ProgramRegister />
          </>
        )}

        <Button
          type="button"
          variant="outline-primary"
          href="https://app.moncoachmobilite.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Je me connecte
        </Button>
      </div>
    </div>
  );
});

export default MobilityCoach;
