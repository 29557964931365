import { ApiHelper } from '../helpers/api.helper';
import { IUser } from '../types/user.type';

const url = 'user';

export const fetchUser = async (): Promise<IUser> => {
  const response = await fetch(ApiHelper.makeUrl(url), ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data?.data;
};

export const updateUser = async (params: IUser): Promise<IUser> => {
  const {
    birthday,
    available,
    date_available: dateAvailable,
    cv,
    cv_fr: cvFr,
    picture,
    cv_images: images,
    cv_fr_images: frImages,
    ...profile
  } = params;

  const response = await fetch(ApiHelper.makeUrl(url), {
    method: 'PUT',
    body: JSON.stringify({
      ...profile,
      manager: {
        birthday,
        available,
        date_available: dateAvailable,
      },
    }),
    ...ApiHelper.makeOptions(),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data?.data;
};

export const uploadCv = async (cv: File, version?: string): Promise<IUser> => {
  const formData = new FormData();
  formData.append('cv', cv);

  if (version) {
    formData.append('version', version);
  }

  const response = await fetch(ApiHelper.makeUrl(`${url}/cv`), {
    method: 'POST',
    body: formData,
    ...ApiHelper.makeOptions(false),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data?.data;
};

export const removeCv = async (version: string): Promise<IUser> => {
  const response = await fetch(ApiHelper.makeUrl(`${url}/cv?version=${version}`), {
    method: 'DELETE',
    ...ApiHelper.makeOptions(false),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data?.data;
};

export const uploadPhoto = async (picture: File): Promise<IUser> => {
  const formData = new FormData();
  formData.append('picture', picture);

  const response = await fetch(ApiHelper.makeUrl(`${url}/photo`), {
    method: 'POST',
    body: formData,
    ...ApiHelper.makeOptions(false),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data?.data;
};
