import { createContext } from 'react';

import { IUser } from './types/user.type';

type IUserContext = {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
};

const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => null,
});

export default UserContext;
