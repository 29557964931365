import React from 'react';

import { Col, Row } from 'react-bootstrap';

import './styles.css';

const Footer = React.memo(() => (
  <Row className="footer">
    <Col lg={12} md={12} className="text-end">
      <img className="logo" src="/assets/logo.png" alt="Logo" />
    </Col>
  </Row>
));

export default Footer;
